export const BASE_URL = 'https://admin.repd.co';
export const REPD_BASE_URL = 'https://app.repd.co/';
export const API_URL = 'https://admin.repd.co/api';

export const AUTH_WEB = {
    domain: 'dev-tmay8ff9.us.auth0.com',
    clientID: 'Ie4yFzikETaD9XbdkCkUe8BULXtK8tIn',
    responseType: 'token',
    realm: 'Username-Password-Authentication'
};
export const AUTH_SOCIAL_WEB = {
    domain: 'dev-tmay8ff9.us.auth0.com',
    clientID: 'Ie4yFzikETaD9XbdkCkUe8BULXtK8tIn',
    responseType: 'id_token',
    redirectUri: BASE_URL + '/profile',
    realm: 'Username-Password-Authentication'
};
